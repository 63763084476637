<template>
    <div class="">
           <footer id="footer">
                <div class="footer-top">
                <div class="container">
                    <div class="row">

                    <div class="col-lg-3 col-md-6 footer-info">
                        <h3><img src="../../assets/img/logo.svg" style="width: 60%; -webkit-filter: brightness(0) invert(1); filter: brightness(0) invert(1);" alt=""></h3>
                        <p>L'excellence d'un service d'outsourcing IT adapté à tous les types et tailles de business</p>
                    </div>

                    <div class="col-lg-3 col-md-6 footer-links">
                        <h4>Qui sommes-nous</h4>
                        <ul>
                        <li><i class="bi bi-chevron-right"></i> <a href="#home">Acceuil</a></li>
                        <li><i class="bi bi-chevron-right"></i> <a href="#ese">Entreprise</a></li>
                        <li><i class="bi bi-chevron-right"></i> <a href="#metiers">Métiers</a></li>
                        <li><i class="bi bi-chevron-right"></i> <a href="#portage">Portage</a></li>
                        <li><i class="bi bi-chevron-right"></i> <a href="/offre/public/">Nos Offres</a></li>
                        </ul>
                    </div>

                    <div class="col-lg-3 col-md-6 footer-contact">
                        <h4>Contactez</h4>
                        <p>
                        12 AVENUE MICHLIFEN  <br> 4EME ETAGE
                        APT N°9 AGDAL RABAT<br>

                        <strong>Phone:</strong> +212 5 37 67 43 96<br>
                        <strong>Email:</strong>contact@bitdyne.com<br>
                        </p>

                        <div class="social-links">
                        <a href="https://twitter.com/FormatimS" target="_blank" class="twitter"><i class="bi bi-twitter"></i></a>
                        <a href="https://www.facebook.com/Bitdyne-digital-innovation-100333906037151" target="_blank" class="facebook"><i class="bi bi-facebook"></i></a>
                        <a href="https://www.linkedin.com/company/bitdyne/" target="_blank" class="linkedin"><i class="bi bi-linkedin"></i></a>
                        <a href="https://www.linkedin.com/in/bitdyne-digital-404774202/" target="_blank" class="linkedin"><i class="bi bi-linkedin"></i></a>
                        <a href="#" class="instagram"><i class="bi bi-instagram"></i></a>
                        </div>

                    </div>

                    <div class="col-lg-3 col-md-6 footer-newsletter">
                        <h4>Rejoignez notre Bitdyne</h4>
                        <p>Bienvenue, votre participation est importante pour nous, merci!</p>
                        <form action="" method="post">
                        <input type="email" name="email"><input type="submit" value="Subscribe">
                        </form>
                    </div>

                    </div>
                </div>
                </div>

    <div class="container">
      <div class="copyright">
        &copy; Copyright <strong>2020 Bitdyne</strong>. All Rights Reserved
      </div>
      <div class="credits">
      
      
      </div>
    </div>
  </footer>
    </div>
</template>
<script>
export default {
    
}
</script>
<style>
#footer {
  background: #045CB6;
  padding: 0 0 30px 0;
  color: #eee;
  font-size: 14px;
}
#footer .footer-top {
  background: #046CB4;
  padding: 60px 0 30px 0;
}
#footer .footer-top .footer-info {
  margin-bottom: 30px;
}
#footer .footer-top .footer-info h3 {
  font-size: 34px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 10px;
  line-height: 1;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: 3px;
  border-left: 4px solid #E41474;
}
#footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Montserrat", sans-serif;
  color: #eee;
}
#footer .footer-top .social-links a {
  display: inline-block;
  background: #333;
  color: #eee;
  line-height: 1;
  margin-right: 4px;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  transition: 0.3s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
#footer .footer-top .social-links a i {
  line-height: 0;
  font-size: 16px;
}
#footer .footer-top .social-links a:hover {
  background: #E41474;
  color: #fff;
}
#footer .footer-top h4 {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 12px;
}
#footer .footer-top h4::before, #footer .footer-top h4::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
}
#footer .footer-top h4::before {
  right: 0;
  background: #555;
}
#footer .footer-top h4::after {
  background: #E41474;
  width: 60px;
}
#footer .footer-top .footer-links {
  margin-bottom: 30px;
}
#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
#footer .footer-top .footer-links ul i {
  padding-right: 8px;
  color: #ddd;
}
#footer .footer-top .footer-links ul li {
  border-bottom: 1px solid #333;
  padding: 10px 0;
}
#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}
#footer .footer-top .footer-links ul a {
  color: #eee;
}
#footer .footer-top .footer-links ul a:hover {
  color: #E41474;
}
#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}
#footer .footer-top .footer-contact p {
  line-height: 26px;
}
#footer .footer-top .footer-newsletter {
  margin-bottom: 30px;
}
#footer .footer-top .footer-newsletter input[type=email] {
  border: 0;
  padding: 6px 8px;
  width: 65%;
}
#footer .footer-top .footer-newsletter input[type=submit] {
  background: #E41474;
  border: 0;
  width: 35%;
  padding: 6px 0;
  text-align: center;
  color: #fff;
  transition: 0.3s;
  cursor: pointer;
}
#footer .footer-top .footer-newsletter input[type=submit]:hover {
  background: #13a456;
}
#footer .copyright {
  text-align: center;
  padding-top: 30px;
}
#footer .credits {
  text-align: center;
  font-size: 13px;
  color: #ddd;
}
</style>