<template>
    <div>
        <form @submit.prevent="ajouterProfiles" enctype="multipart/form-data" >
      <div class="mb-3">
        <label for="formFile" class="form-label"
          >chose fichier excel</label
        >
        <input class="form-control" type="file" @change="onFileChange" id="formFile" />
        <button type="submit">Ajouter profiles</button>
      </div>
    </form>
 <hr>
       <form @submit.prevent="uploadCVFiles" enctype="multipart/form-data" >
      <div class="mb-3">
        <label for="formFile" class="form-label"
          >chose fichier word</label
        >
        <input class="form-control" type="file" multiple @change="fieldChange" id="formFile" />
        <button type="submit">Ajouter cvs</button>
      </div>
    </form> 

    </div>
</template>
<script>
 import axios from 'axios'

export default {


  name:'profilesCV',
  data(){
    return{
     
        uploaded_file :'',
        attachments:[]
    
    
    }
  },
  methods:{
     onFileChange(e) {
            this.uploaded_file = e.target.files[0];
          
        },
    ajouterProfiles(){
 let formData = new FormData();
            formData.append('uploaded_file', this.uploaded_file)
            axios.post('/cv_anonymme/back/api/upload-profiles',formData).then(
      res => {
        console.log(res)
      }
    ).catch(errr=>console.log(errr))
    },
    fieldChange(e){
         let selectedFiles=e.target.files;
            if(!selectedFiles.length){  return false; }
            for(let i=0;i<selectedFiles.length;i++){
                this.attachments.push(selectedFiles[i]);
            }
            console.log(this.attachments);
    },
     uploadCVFiles(){
  try {
                let form = new FormData();
                for(let i=0; i<this.attachments.length;i++){
                    form.append('pics[]',this.attachments[i]);
                }
                // console.log(form);
                // const config = { headers: { 'Content-Type': 'multipart/form-data' } };
                // document.getElementById('upload-file').value=[];
                axios.post('/cv_anonymme/back/api/store-all-cv', form).then(res =>{
                    console.log(res.data)
                }).catch()
            } catch (error) {
                console.log('check error: ', error.response.data);
                return error.response.data;
            }


        //     this.storeAllCV(this.attachments)
        //         .then((res) => { this.getResults(), this.attachments = [], this.flashMessage.success({ message: res.data.message, time: 5000 }) })
        //         .catch(() => this.flashMessage.error({ message: 'Fichier pdf non téléchargé !', time: 5000 }));
        },
  }   
}
</script>
