import { createRouter , createWebHistory} from 'vue-router';
import PageHome  from "../components/PageHome/PageHome.vue";
import AjSkills  from "../components/AjouterSkills/AjSkills.vue";
import profiles  from "../components/profiles/profilesCV.vue";
import voirCV  from "../components/profiles/voirCV.vue";
// import dashboard  from "../components/dashboard.vue";
// import ajoutOffres from "../components/ajoutOffres.vue";
// import editOffre from "../components/editOffre.vue";
// import candidats from "../components/candidats.vue";
// import detailsOffre from "../components/detailsOffre.vue"

const routes = [
  {  path: "/",
     name :"PageHome",
     component : PageHome
   },
   {  path: "/AjSkills",
      name :"AjSkills",
      component : AjSkills
   },
   
   {  path: "/profiles",
      name :"profiles",
      component : profiles
   },
   {  path: "/voirCV",
      name :"voirCV",
      component : voirCV,
      props:true
   },
//    {
//       path: "/ajoutOffre",
//       name:"ajoutOffre",
//       component:ajoutOffres
//    },
//    {
//       path: "/editOffre",
//       name:"editOffre",
//       component:editOffre,
//       props: true,
//    },
//    {
//      path: "/candidats",
//      name:"candidats",
//      component:candidats,
//      props: true,
//   },
//   {
//     path: "/detailsOffre/:id",
//     name:"detailsOffre",
//     component:detailsOffre,
//     props: true,
   

//  }


];


export default  createRouter({
   history:createWebHistory(),
   routes, 
  
});