<template>
  <div class="flex flex-col flex-1 w-full">
    <NavBar/>
   
   
      
    <SkillsP />
  
  
   
    <FooTer />
  </div>
</template>
<script>
import SkillsP from "./SkillsP.vue";
import NavBar from "../NavBar/NavBar.vue"
import FooTer from "../Footer/FooTer.vue"

export default {
  name: "PageHome",
  setup(){
   
  
  },
  components: {
  
    SkillsP,
    NavBar,
    FooTer,
  
  },
};
</script>

<style>
.skills{
  background-color: bisque;
}
</style>


