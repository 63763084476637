<template >
    <div class="globale">
      <div class="fixed-button" @click="showModal=true" >
        <button class="btn btn-success"><i class="bi bi-chat-dots" ></i></button>
      </div>
        <iframe v-bind:src="'https://docs.google.com/gview?url='+url + '&embedded=true'" 
        frameborder="0" width="100%" height="800" style="background-color: #444444;" />
          <!-- <vue-pdf-embed :source="url" /> -->
          <!-- <embed
	:src="url"
	type="application/pdf"
	width="100%"
	height="100%"
/> -->

<div class="">sfsfsfsfsf</div>
    </div>



      <vue-final-modal v-model="showModal" classes="modal-container" content-class="modal-content" >

                   <form @submit="envoyerEmail">
                   <div class="row mt-3">
                    
                 
                  
                      <div class="col-sm-6 mb-3 ">
                          <label for="exampleFormControlInput1" class="form-label">nom complet</label>
                          <input type="text" class="form-control" v-model="data.nomc" id="exampleFormControlInput1" placeholder="name@example.com">
                        </div>
                      <div class=" col-md-6 mb-md-3">
                          <label for="exampleFormControlInput1" class="form-label">entreprise</label>
                          <input type="text" class="form-control"  v-model="data.entreprise" id="exampleFormControlInput1" placeholder="name@example.com">
                        </div>
                      <div class="col-md-6  mb-md-3">
                          <label for="exampleFormControlInput1" class="form-label">Telephone</label>
                          <input type="number" class="form-control"  v-model="data.telephone" id="exampleFormControlInput1" placeholder="name@example.com">
                        </div>
                      <div class="col-md-6  mb-md-3">
                          <label for="exampleFormControlInput1" class="form-label">Email address</label>
                          <input type="email" class="form-control" v-model="data.Email" id="exampleFormControlInput1" placeholder="name@example.com">
                        </div>
                      <div class="col-md-6  mb-md-3">
                          <label for="exampleFormControlInput1"   class="form-label">subject</label>
                          <input type="text" class="form-control" v-model="data.subject" id="exampleFormControlInput1" placeholder="name@example.com">
                        </div>
                        <div class="col-md-6  mb-md-3">
                          <label for="exampleFormControlTextarea1" class="form-label">message</label>
                          <textarea class="form-control" v-model="data.message" id="exampleFormControlTextarea1" rows="3"></textarea>
                        </div>
                        <div class="col-md-12 mt-3 d-flex justify-content-center">
                         <button type="submit" class="btn btn-success"> envoyer</button>
                         </div>
                          </div>

                     </form>

                </vue-final-modal>

</template>

<script>

import { VueFinalModal } from 'vue-final-modal'
import axios from 'axios'

export default {
   
    data() {
        return {
          
            //  url:"bitdyne.com/intern/public/showPdf?id=256&pdfID=926&url=https%3A%2F%2Fbitdyne.com%2Fintern%2Fpublic%2Fstorage%2FCV%2Ffadoua.ganouf%40gmail.com.docx",
          url:"https://www.bitdyne.com/cv_anonymme/back/public"+ this.$route.params.url,
          
            docType:'office',
                  showModal:false,
                   data:{},
                         messageError:""


           
          
        }
    },
    components:{
        VueFinalModal
    }
    ,
    methods: {
        envoyerEmail(){
        if(this.data.nomC !== "" && this.data.email !== "" && this.data.subject !== "" && this.data.message !== ""){
          axios.post("/cv_anonymme/back/public/api/envoyer-email",this.data).then(res=>{console.log(res)})
        }else{
          this.messageError="mslmdslmdmdlsdmsl"
        }
      }
       
    },
    computed: {
      
    },
   


}
</script>

<style>
    .globale{
        position: relative;
    }
    .fixed-button{
        position: fixed;
        right: 50px;
        top: 600px;
    }
</style>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
 

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
    width: 70%;
    height: 60%;
}
@media(max-width:720px){
 ::v-deep .modal-content {
  margin-top: 60px;
     width: 90%;
    height: 88%;
  }
}
.modal__title {
  font-size: 1.5rem;
  font-weight: 700;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>