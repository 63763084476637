<template>
  <div class="">
  <div class="container my-3  box-skills shadow-sm p-3">
    <div class="row">
       
     <div class="flex mt-6 text-sm col-4 col-sm-2 col-md-1  my-2"  v-for="(skill) in skills" :key="skill.id">
                <label class="flex items-center dark:text-gray-400">

                  <input
                    type="checkbox"
                    :value="skill.skills" :id="skill.id"   v-model="select_skills"
                    class="text-purple-600 form-checkbox focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray"
                  />
                  <span class="ml-2">
                   {{skill.skills}}
                  </span>
                </label>
              </div>
    </div>



    </div>
   <div class="w-full overflow-hidden rounded-lg shadow-xs container mb-5">
              <div class="w-full overflow-x-auto">
                <table class="w-full whitespace-no-wrap">
                  <thead>
                    <tr
                      class="text-xs  text-center font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
                    >
                     
                      <th class="px-4 py-3">Référence</th>
                      <th class="px-4 py-3">Expérience</th>
                      <th class="px-4 py-3">Compétence</th>
                      <th class="px-4 py-3">action</th>
                    </tr>
                  </thead>
                  <tbody
                    class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800"
                  >
                  

                     <tr v-show="profiles" v-for="(profile) in profiles" :key="profile.id" class="text-gray-700 dark:text-gray-400 text-center ">
                                    <td><small>{{ profile.reference }}</small></td>
                                     <td scope="row"><small>{{moment(profile.date_debut_experience , "YYYY-MM-DD").fromNow()}}</small></td> 
                                    <td><small style="font-size: 12px;"><b>{{ profile.skill1 ? profile.skill1 : '' }} {{ profile.skill2 ? ' | | '+profile.skill2 : '' }} {{ profile.skill3 ? ' | | '+profile.skill3 : '' }} {{ profile.skill4 ? ' | | '+profile.skill4 : '' }} {{ profile.skill5 ? ' | | '+profile.skill5 : '' }}</b></small></td>
                                    <td class="d-flex">
                                       <router-link :to="{name:'voirCV', params : {url:profile.pdf.path} }" v-if="profile.pdf" class="btn bnt-action mr-2"><i class="bi bi-file-earmark-person m"></i></router-link>
                                       <button @click="showModal = true" class="btn bnt-action"><i class="bi bi-envelope"></i></button>

                                        <!-- <router-link v-if="profile.pdf" :to="{name: 'profile.showPdf', query: {id: profile.id, pdfID: profile.pdf.id, url: profile.pdf.path}}" target="_blank" data-bs-toggle="tooltip" data-bs-placement="top" title="Voir PDF">
                                            <svg xmlns="http://www.w3.org/2000/svg" data-bs-toggle="tooltip" data-bs-placement="top" title="Voir CV" aria-hidden="true" role="img" style="vertical-align: -0.125em;" width="1.1em" height="1.5em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 448 512"><path fill="#ec407a" d="M48 32C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48H48zm98.88 133.234c19.636 0 37.082 6.789 49.929 16.971c11.88 9.452 17.444 18.907 22.298 27.393l-33.923 16.949c-2.427-5.565-5.347-11.387-12.846-17.682c-8.248-6.552-16.478-8.484-23.524-8.484c-27.626 0-42.17 25.693-42.17 54.287c0 37.573 19.161 56.22 42.17 56.22c22.3 0 31.278-15.51 37.08-25.435L219.6 302.66c-6.315 9.926-12.374 19.635-25.95 29.069c-7.262 5.09-23.977 15.037-47.736 15.037C100.586 346.766 64 313.81 64 255.87c0-50.636 34.415-90.637 82.88-90.637zm75.483 5.328h45.565L303.31 292.24l35.125-121.678H384l-59.379 171.112H281.01l-58.647-171.111z"/></svg>
                                        </router-link> -->
                                       <!--  <a v-if="profile.pdf" :href="profile.pdf.path + '#toolbar=0&navpanes=0&scrollbar=0;readonly=true;disableprint=true;'" target="_blank">
                                            <svg xmlns="http://www.w3.org/2000/svg" data-bs-toggle="tooltip" data-bs-placement="top" title="Voir CV" aria-hidden="true" role="img" style="vertical-align: -0.125em;" width="1.1em" height="1.5em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 448 512"><path fill="#ec407a" d="M48 32C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48H48zm98.88 133.234c19.636 0 37.082 6.789 49.929 16.971c11.88 9.452 17.444 18.907 22.298 27.393l-33.923 16.949c-2.427-5.565-5.347-11.387-12.846-17.682c-8.248-6.552-16.478-8.484-23.524-8.484c-27.626 0-42.17 25.693-42.17 54.287c0 37.573 19.161 56.22 42.17 56.22c22.3 0 31.278-15.51 37.08-25.435L219.6 302.66c-6.315 9.926-12.374 19.635-25.95 29.069c-7.262 5.09-23.977 15.037-47.736 15.037C100.586 346.766 64 313.81 64 255.87c0-50.636 34.415-90.637 82.88-90.637zm75.483 5.328h45.565L303.31 292.24l35.125-121.678H384l-59.379 171.112H281.01l-58.647-171.111z"/></svg>
                                        </a> -->
                                    </td>
                                </tr>

                  </tbody>
                </table>
               
              </div>
             
            </div>
          
  
  </div>




   <vue-final-modal v-model="showModal" classes="modal-container" content-class="modal-content" >

                     <form @submit="envoyerEmail">
                   <div class="row mt-3">
                    
                 
                  
                      <div class="col-sm-6 mb-3 ">
                          <label for="exampleFormControlInput1" class="form-label">nom complet</label>
                          <input type="text" class="form-control" v-model="data.nomc" id="exampleFormControlInput1" placeholder="name@example.com">
                        </div>
                      <div class=" col-md-6 mb-md-3">
                          <label for="exampleFormControlInput1" class="form-label">entreprise</label>
                          <input type="text" class="form-control"  v-model="data.entreprise" id="exampleFormControlInput1" placeholder="name@example.com">
                        </div>
                      <div class="col-md-6  mb-md-3">
                          <label for="exampleFormControlInput1" class="form-label">Telephone</label>
                          <input type="number" class="form-control"  v-model="data.telephone" id="exampleFormControlInput1" placeholder="name@example.com">
                        </div>
                      <div class="col-md-6  mb-md-3">
                          <label for="exampleFormControlInput1" class="form-label">Email address</label>
                          <input type="email" class="form-control" v-model="data.Email" id="exampleFormControlInput1" placeholder="name@example.com">
                        </div>
                      <div class="col-md-6  mb-md-3">
                          <label for="exampleFormControlInput1"   class="form-label">subject</label>
                          <input type="text" class="form-control" v-model="data.subject" id="exampleFormControlInput1" placeholder="name@example.com">
                        </div>
                        <div class="col-md-6  mb-md-3">
                          <label for="exampleFormControlTextarea1" class="form-label">message</label>
                          <textarea class="form-control" v-model="data.message" id="exampleFormControlTextarea1" rows="3"></textarea>
                        </div>
                        <div class="col-md-12 mt-3 d-flex justify-content-center">
                         <button type="submit" class="btn btn-success"> envoyer</button>
                         </div>
                          </div>

                     </form>

                </vue-final-modal>



</template>

<script>
import axios from 'axios'
import moment from 'moment'
import { VueFinalModal } from 'vue-final-modal'


export default {
  name:'skillsP',
   mounted(){

   axios.get("/cv_anonymme/back/api/skills-index")
   .then(res=> {
    this.skills=res.data.data
    console.log(res.data.data)
    console.log(this.skills)
   
   })
   .catch(err=>console.log(err))

  },
   components: {
    VueFinalModal,
   
  },
    data(){
    return{

      skills:null,
      select_skills:[],
      profiles:null,
      moment:moment,
      showModal:false,
      data:{},
      messageError:""

    }
    },
    methods:{
      search(){
          axios.get('/cv_anonymme/back/api/searchProfiles', {params:{select_members: this.select_skills}}).then(res=>{
           console.log(res.data)
           this.profiles=res.data
          }).catch (error=> {
                console.log(error)
            })
      },
      envoyerEmail(){
        if(this.data.nomC !== "" && this.data.email !== "" && this.data.subject !== "" && this.data.message !== ""){
          axios.post("/cv_anonymme/back/api/envoyer-email",this.data).then(res=>{console.log(res)})
        }else{
          this.messageError="mslmdslmdmdlsdmsl"
        }
      }
    },
      watch: {
      
        select_skills(){
            if (this.select_skills.length > 0) {
                this.search();
            } else {
                return this.select_skills.length = 0
            }
        },
    },
 
}
</script>

<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
 

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
    width: 70%;
    height: 60%;
}
@media(max-width:720px){
 ::v-deep .modal-content {
  margin-top: 60px;
     width: 90%;
    height: 88%;
  }
}
.modal__title {
  font-size: 1.5rem;
  font-weight: 700;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}

.box-skills{
  background-color: #e8eaeb;
 
}
.bnt-action{
  width: 30px;
  height: 30px;
  margin: 5px;
  display:flex;
  justify-content: center;
  align-items: center;
  background-color: #E41474;
  color: white;
}
</style>