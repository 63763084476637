<template>
  <div>
    <form @submit.prevent="ajouter" enctype="multipart/form-data" >
      <div class="mb-3">
        <label for="formFile" class="form-label"
          >chose fichier excel</label
        >
        <input class="form-control" type="file" @change="onFileChange" id="formFile" />
        <button type="submit">Ajouter</button>
      </div>
    </form>
  </div>
</template>
<script>
import axios from 'axios'

export default {
  name:'ajSkills',
  data(){
    return{
     
        uploaded_file :''
    
    
    }
  },
  methods:{
     onFileChange(e) {
            this.uploaded_file = e.target.files[0];
          
        },
    ajouter(){
 let formData = new FormData();
            formData.append('uploaded_file', this.uploaded_file);
    axios.post('/cv_anonymme/back/public/api/upload-content',formData).then(
      res => {
        console.log(res)
      }
    ).catch(errr=>console.log(errr))
    }
  }
}
</script>
